
















import Vue from 'vue'
import store from '@/store'
import SearchesReportStore from '@/store/searchV3Report/searches'
import SearchMiniGraphV3 from '@/views/components/search/SearchMiniGraphV3.vue'

export default Vue.extend({
  components: {
    SearchMiniGraphV3,
  },
  computed: {
    chartData() {
      return SearchesReportStore.state.transactionCount.data
    },
    start() {
      return (store.state as any).search.dateRange.start
    },
    dataLoading() {
      return SearchesReportStore.state.generalPerformanceIsLoading
    },
    chartSummaryData() {
      return SearchesReportStore.state.transactionCount.summaryData
    },
  },
  watch: {
    start() {
      SearchesReportStore.commit('setTimeFrame', (store.state as any).search.dateRange)
      SearchesReportStore.dispatch('getGeneralPerformance')
    },
  },
  mounted() {
    SearchesReportStore.dispatch('getGeneralPerformance')
  },
})
