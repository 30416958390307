














import Vue, { PropType } from 'vue'
import ApexCharts from 'vue-apexcharts'
import colors from '@/layouts/colors'

interface DateStatistics {
  count: number,
  data: string
}

export default Vue.extend({
  components: {
    ApexCharts,
  },
  props: {
    chartData: {
      type: Array as PropType<Array<DateStatistics>>,
      required: true,
    },
    displayName: {
      type: String,
      required: true,
    },
    dataLoading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      barChart: {
        series: [{
          name: '',
          data: [] as number[],
        }],
        chartOptions: {
          colors: [colors.chart.primary],
          chart: {
            toolbar: {
              tools: {
                download: false,
              },
            },
          },
          plotOptions: {
            bar: {
              horizontal: true,
            },
          },
          dataLabels: {
            enabled: false,
          },
          xaxis: {
            categories: [] as string[],
          },
        },
      },
    }
  },
  watch: {
    chartData() {
      const series = []
      const axis = []
      for (let i = 0; i < this.chartData.length; i++) {
        series.push(this.chartData[i].count)
        axis.push(this.chartData[i].data)
      }

      this.changeData(axis, series)
    },
  },
  methods: {
    changeData(axis: string[], series: number[]) {
      this.barChart.series = [{
        data: series,
        name: this.displayName,
      }]
      this.barChart.chartOptions.xaxis.categories = axis;
      (this.$refs.chart as any).refresh()
    },
  },
})
