











import Vue from 'vue'
import PhrasesReportStore from '@/store/searchV3Report/phrases'
import store from '@/store'
import BarStatistics from '@/views/components/search/BarStatistics.vue'

export default Vue.extend({
  components: { BarStatistics },
  computed: {
    chartData() {
      return PhrasesReportStore.state.emptyResultsPhrasesData.data
    },
    start() {
      return (store.state as any).search.dateRange.start
    },
    dataLoading() {
      return PhrasesReportStore.state.emptyResultsPhrasesData.dataLoading
    },
  },
  watch: {
    start() {
      PhrasesReportStore.dispatch('getEmptyResultsPhrases')
    },
  },
  mounted() {
    PhrasesReportStore.dispatch('getEmptyResultsPhrases')
  },
  methods: {},
})
