









import Vue from 'vue'
import store from '@/store'
import BarStatistics from '@/views/components/search/BarStatistics.vue'
import SearchesReportStore from '@/store/searchV3Report/searches'

export default Vue.extend({
  components: { BarStatistics },
  computed: {
    chartData() {
      return SearchesReportStore.state.perInstanceCount.data
    },
    start() {
      return (store.state as any).search.dateRange.start
    },
    dataLoading() {
      return SearchesReportStore.state.perInstanceCount.dataLoading
    },
  },
  watch: {
    start() {
      SearchesReportStore.dispatch('getSearchesCountPerInstance')
    },
  },
  mounted() {
    SearchesReportStore.dispatch('getSearchesCountPerInstance')
  },
  methods: {},
})
