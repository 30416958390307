










import Vue from 'vue'
import store from '@/store'
import PhrasesReportStore from '@/store/searchV3Report/phrases'
import BarStatistics from '@/views/components/search/BarStatistics.vue'

export default Vue.extend({
  components: {
    BarStatistics,
  },
  computed: {
    chartData() {
      return PhrasesReportStore.state.conversionsPhrasesData.transactionsAmount.data
    },
    start() {
      return (store.state as any).search.dateRange.start
    },
    dataLoading() {
      return PhrasesReportStore.state.conversionsPhrasesData.transactionsAmount.dataLoading
    },
  },
  watch: {
    start() {
      PhrasesReportStore.commit('setTimeFrame', (store.state as any).search.dateRange)
      PhrasesReportStore.dispatch('getAmountConversionPhrases')
    },
  },
  mounted() {
    PhrasesReportStore.dispatch('getAmountConversionPhrases')
  },
  methods: {},
})
