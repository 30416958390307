import Vue from 'vue'
import Vuex from 'vuex'
import moment from 'moment/moment'
import {
  CountForData,
  ReportTimeFrame,
  SearchReportApiRequestHelper,
} from '@/store/searchV3Report/searchReportApiRequestHelper'
import { AxiosResponse } from 'axios'
import Notifications from '@/store/notifications/notifications'

Vue.use(Vuex)

interface PhrasesReport {
  timeFrame: ReportTimeFrame;
  popularPhrasesData: {
    data: CountForData[],
    dataLoading: boolean
  };
  emptyResultsPhrasesData: {
    data: CountForData[],
    dataLoading: boolean
  };
  mostClickedPhrasesData: {
    data: CountForData[],
    dataLoading: boolean
  };
  conversionsPhrasesData: {
    transactionsCount: {
      data: CountForData[],
      dataLoading: boolean
    };
    transactionsAmount: {
      data: CountForData[],
      dataLoading: boolean
    }
  };
}

const getInitialState = (): PhrasesReport => ({
  timeFrame: {
    start: moment().subtract(1, 'week').set({ hour: 23, minute: 59, second: 59 }).format('YYYY-MM-DD hh:mm:ss'),
    end: moment().set({ hour: 23, minute: 59, second: 59 }).format('YYYY-MM-DD hh:mm:ss'),
  },
  popularPhrasesData: { data: [], dataLoading: false },
  emptyResultsPhrasesData: { data: [], dataLoading: false },
  mostClickedPhrasesData: { data: [], dataLoading: false },
  conversionsPhrasesData: {
    transactionsCount: { data: [], dataLoading: false },
    transactionsAmount: { data: [], dataLoading: false },
  },
})

export default new Vuex.Store({
  state: getInitialState(),
  mutations: {
    setTimeFrame(state: any, timeFrame: ReportTimeFrame) {
      state.timeFrame.start = moment(timeFrame.start).format('YYYY-MM-DD 00:00:00')
      state.timeFrame.end = moment(timeFrame.end).format('YYYY-MM-DD 23:59:59')
    },
    setPopularPhrasesData(state: any, data: CountForData[]) {
      state.popularPhrasesData.data = data
    },
    setPopularPhrasesDataLoading(state: any, loading: boolean) {
      state.popularPhrasesData.dataLoading = loading
    },
    setEmptyResultsPhrasesData(state: any, data: CountForData[]) {
      state.emptyResultsPhrasesData.data = data
    },
    setEmptyResultsPhrasesDataLoading(state: any, loading: boolean) {
      state.emptyResultsPhrasesData.dataLoading = loading
    },
    setMostClickedPhrasesData(state: any, data: CountForData[]) {
      state.mostClickedPhrasesData.data = data
    },
    setMostClickedPhrasesDataLoading(state: any, loading: boolean) {
      state.mostClickedPhrasesData.dataLoading = loading
    },
    setConversionTransactionsCountData(state: any, data: CountForData[]) {
      state.conversionsPhrasesData.transactionsCount.data = data
    },
    setConversionTransactionsCountDataLoading(state: any, loading: boolean) {
      state.conversionsPhrasesData.transactionsCount.dataLoading = loading
    },
    setConversionTransactionsAmountData(state: any, data: CountForData[]) {
      state.conversionsPhrasesData.transactionsAmount.data = data
    },
    setConversionTransactionsAmountDataLoading(state: any, loading: boolean) {
      state.conversionsPhrasesData.transactionsAmount.dataLoading = loading
    },
  },
  actions: {
    getPopularPhrases(context: any) {
      if (context.state.popularPhrasesData.dataLoading) {
        return
      }
      context.commit('setPopularPhrasesDataLoading', true)

      SearchReportApiRequestHelper.getPopularPhrases(context.state.timeFrame)
        .then((result: AxiosResponse<Array<CountForData>>) => {
          context.commit('setPopularPhrasesDataLoading', false)
          if (result.status !== 200) {
            Notifications.commit('addError', 'search.errorGettingSearchInstances')
            return
          }
          context.commit('setPopularPhrasesData', result.data)
        })
        .catch(error => {
          context.commit('setPopularPhrasesDataLoading', false)
          Notifications.commit('addPopupError', error)
        })
    },
    getEmptyResultsPhrases(context: any) {
      if (context.state.emptyResultsPhrasesData.dataLoading) {
        return
      }
      context.commit('setEmptyResultsPhrasesDataLoading', true)
      SearchReportApiRequestHelper.getEmptyResultsPhrases(context.state.timeFrame)
        .then((result: AxiosResponse<Array<CountForData>>) => {
          context.commit('setEmptyResultsPhrasesDataLoading', false)
          if (result.status !== 200) {
            Notifications.commit('addError', 'search.errorGettingSearchInstances')
            return
          }
          context.commit('setEmptyResultsPhrasesData', result.data)
        })
        .catch(error => {
          context.commit('setEmptyResultsPhrasesDataLoading', false)
          Notifications.commit('addPopupError', error)
        })
    },
    getMostClickedPhrases(context: any) {
      if (context.state.mostClickedPhrasesData.dataLoading) {
        return
      }
      context.commit('setMostClickedPhrasesDataLoading', true)
      SearchReportApiRequestHelper.getMostClickedPhrases(context.state.timeFrame)
        .then((result: AxiosResponse<Array<CountForData>>) => {
          context.commit('setMostClickedPhrasesDataLoading', false)

          if (result.status !== 200) {
            Notifications.commit('addError', 'search.errorGettingSearchInstances')
            return
          }
          context.commit('setMostClickedPhrasesData', result.data)
        })
        .catch(error => {
          context.commit('setMostClickedPhrasesDataLoading', false)

          Notifications.commit('addPopupError', error)
        })
    },
    getCountConversionPhrases(context: any) {
      if (context.state.conversionsPhrasesData.transactionsCount.dataLoading) {
        return
      }
      context.commit('setConversionTransactionsCountDataLoading', true)
      SearchReportApiRequestHelper.getPhrasesWithBestCountConversion(context.state.timeFrame)
        .then((result: AxiosResponse<Array<CountForData>>) => {
          context.commit('setConversionTransactionsCountDataLoading', false)

          if (result.status !== 200) {
            Notifications.commit('addError', 'search.errorGettingSearchInstances')
            return
          }
          context.commit('setConversionTransactionsCountData', result.data)
        })
        .catch(error => {
          context.commit('setConversionTransactionsCountDataLoading', false)

          Notifications.commit('addPopupError', error)
        })
    },
    getAmountConversionPhrases(context: any) {
      if (context.state.conversionsPhrasesData.transactionsAmount.dataLoading) {
        return
      }
      context.commit('setConversionTransactionsAmountDataLoading', true)
      SearchReportApiRequestHelper.getPhrasesWithBestAmountConversion(context.state.timeFrame)
        .then((result: AxiosResponse<Array<CountForData>>) => {
          context.commit('setConversionTransactionsAmountDataLoading', false)

          if (result.status !== 200) {
            Notifications.commit('addError', 'search.errorGettingSearchInstances')
            return
          }
          context.commit('setConversionTransactionsAmountData', result.data)
        })
        .catch(error => {
          context.commit('setConversionTransactionsAmountDataLoading', false)

          Notifications.commit('addPopupError', error)
        })
    },
  },
})
