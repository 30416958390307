



























































import Vue from 'vue'
import SearchesPerDay from '@/views/reports/SearchesPerDay.vue'
import PopularPhrasesWordCloud from '@/views/reports/PopularPhrasesWordCloud.vue'
import SearchInstances from '@/views/reports/SearchInstances.vue'
import PopularPhrasesBar from '@/views/reports/PopularPhrasesBar.vue'
import EmptyResultsPhrases from '@/views/reports/EmptyResultsPhrases.vue'
import MostClickedPhrases from '@/views/reports/MostClickedPhrases.vue'
import ConversionBasedOnCountTransactionByPhrases from '@/views/reports/ConversionBasedOnCountTransactionByPhrases.vue'
import ConversionBasedOnAmountTransactionByPhrases
  from '@/views/reports/ConversionBasedOnAmountTransactionByPhrases.vue'
import SearchCount from '@/views/reports/miniGraph/SearchCount.vue'
import ClicksCount from '@/views/reports/miniGraph/ClicksCount.vue'
import ClickThroughtRate from '@/views/reports/miniGraph/ClickThroughtRate.vue'
import OrdersCount from '@/views/reports/miniGraph/OrdersCount.vue'
import SalesTotalCount from '@/views/reports/miniGraph/SalesTotalCount.vue'
import ConversionPercent from '@/views/reports/miniGraph/ConversionPercent.vue'

export default Vue.extend({
  components: {
    ConversionPercent,
    SalesTotalCount,
    OrdersCount,
    ClickThroughtRate,
    ClicksCount,
    SearchCount,
    ConversionBasedOnAmountTransactionByPhrases,
    ConversionBasedOnCountTransactionByPhrases,
    MostClickedPhrases,
    EmptyResultsPhrases,
    PopularPhrasesBar,
    SearchInstances,
    PopularPhrasesWordCloud,
    SearchesPerDay,
  },

})
