<template>
  <div class="vx-row">
    <div class="vx-col w-full">
      <vs-row vs-type="flex" vs-justify="space-between">
        <vs-col :key="1" vs-type="flex" vs-justify="left" vs-align="right" vs-w="2">
          <vs-icon :icon="icon" size="large" :color="color" />
        </vs-col>
        <vs-col :key="2" vs-type="flex" vs-justify="right" vs-align="right" vs-w="10">
          <div class="truncate" align="right">
            <h2 class="mb-1 font-bold">
              {{ summary }}{{ suffix }}
            </h2>
            <span>{{ title }}</span>
          </div>
        </vs-col>
      </vs-row>
      <b-overlay :show="dataLoading" spinner-variant="primary" variants="white" opacity="0.6">
        <apex-charts
          ref="chart"
          type="area"
          height="150"
          :options="barChart.chartOptions"
          :series="barChart.series"
        />
      </b-overlay>
    </div>
  </div>
</template>

<script>
import ApexCharts from 'vue-apexcharts'

export default {
  components: {
    ApexCharts,
  },
  props: {
    graphsData: {
      type: Array,
      required: true,
    },
    color: {
      type: String,
      default: '#2abb9d',
    },
    title: {
      type: String,
      required: true,
    },
    tooltip: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    suffix: {
      type: String,
      default: '',
    },
    dataLoading: {
      type: Boolean,
      default: false,
    },
    summaryData: {
      type: Number,
      default: 0,
    },
    fractionDigits: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      summary: 0,
      barChart: {
        series: [{
          name: '',
          data: [],
        }],
        chartOptions: {
          chart: {
            id: this.graphsDataColumn,
            group: 'social',
            toolbar: false,
            enabled: false,
          },
          xaxis: {
            type: 'category',
            categories: [],
            labels: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
            crosshairs: {
              show: false,
            },
            tooltip: {
              enabled: false,
            },
          },
          yaxis: {
            labels: {
              show: false,
              minWidth: 1,
            },
          },
          colors: [this.color, '#28C76F', '#EA5455', '#FF9F43', '#21373f'],
          plotOptions: {
            bar: {
              horizontal: true,
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'smooth',
          },
          grid: {
            show: false,
          },
          legend: {
            show: false,
          },
          fill: {
            gradient: {
              enabled: true,
              opacityFrom: 0.55,
              opacityTo: 0,
            },
          },
          markers: {
            size: 0,
          },
        },
      },
    }
  },
  watch: {
    graphsData() {
      this.reloadData()
    },
    dataLoading() {
      return this.dataLoading
    },
  },
  methods: {
    reloadData() {
      const series = []
      const axis = []

      for (let i = 0; i < this.graphsData.length; i++) {
        const item = this.graphsData[i]
        series.push(item.count)
        axis.push(item.date)
      }

      this.summary = this.summaryData.toFixed(this.fractionDigits ?? 0)

      this.barChart.series = [{
        data: series,
        name: this.tooltip,
      }]

      this.barChart.chartOptions.xaxis.categories = axis
      this.$refs.chart.refresh()
    },
  },
}
</script>
