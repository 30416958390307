
















import Vue, { PropType } from 'vue'
import wordcloud from '@/views/components/word-cloud/WordCloud.vue'
import colors from '@/layouts/colors'

interface DateStatistics {
  count: number,
  data: string
}

export default Vue.extend({
  components: { wordcloud },
  props: {
    chartData: {
      type: Array as PropType<Array<DateStatistics>>,
      required: true,
    },
    displayName: {
      type: String,
      required: true,
    },
    dataLoading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      phrases: [],
      rotate: { from: 0, to: 0, numOfOrientation: 1 },
      colors: colors.chart.primaryShade,
      fontScale: 'log',
    }
  },
})
