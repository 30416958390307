












import Vue from 'vue'
import GraphStatistics from '@/views/components/search/GraphStatistics.vue'

import SearchesReportStore from '@/store/searchV3Report/searches'
import store from '@/store'

export default Vue.extend({
  components: {
    GraphStatistics,
  },
  computed: {
    chartData() {
      return SearchesReportStore.state.allSearches.data
    },
    start() {
      return (store.state as any).search.dateRange.start
    },
    dataLoading() {
      return SearchesReportStore.state.allSearches.dataLoading
    },
  },
  watch: {
    start() {
      SearchesReportStore.commit('setTimeFrame', (store.state as any).search.dateRange)
      SearchesReportStore.dispatch('getAllSearchesCountPerDay')
    },
  },
  mounted() {
    SearchesReportStore.dispatch('getAllSearchesCountPerDay')
  },
  methods: {},
})
